import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop } from '../../containers'
import Hero from '../../components/hero'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { MdHelpOutline, MdHelp } from "react-icons/md";
import ReactTooltip from 'react-tooltip';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import houseHackingImg from '../../assets/house-hacking-2x2.png'
import Sidebar from '../../components/Sidebar'
import GoogleAdWrapper from '../../components/GoogleAd'

const H2 = styled.h2`
  font-weight: 300;
`

const CalculatorPanel = styled.div`
  margin: 0 auto;
  /* padding: 24px; */
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  max-width: 1200px;

  @media (max-width: 500px) {
    border: none;
    border-radius: 0px;
  }
`

const CalculatorGrid = styled.div`
  padding: 24px;
  /* margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px; */
  display: grid;
  /* grid-template-columns: minmax(300px, 1fr) 3fr; */
  grid-template-columns: 1fr;
  grid-gap: 48px;
  align-items: start;
  /* max-width: 1200px; */

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`

const GraphDesktop = styled.div`
  display: block;

  @media (max-width: 600px) {
    display: none;
  }
`

const GraphMobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 0px 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }
`

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 1rem;
  font-weight: 400;
  display: grid;
  grid-template-columns: minmax(150px, 1fr) 20px;
  width: 100%;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`

const GroupLabel = styled.h3`
  display: block;
  width: 100%;
  color: ${(props) => props.theme.theme.text.primary};
  text-transform: uppercase;
  margin: 0 0 24px 0;
  font-weight: 500;
  font-size: 0.9rem;
`

const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  align-items: start;
  padding: 0 16px;
`

const FlexInput = styled.div`
  margin: 0px 12px 12px 0;
  width: 100%;
`;

const InputItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 4px;
  align-items: center;
  margin: 0 0 0 0;
`;

const AllocationGroup = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  margin: 0 0 12px 0;
  width: 100%;

  h3 {
    text-transform: uppercase;
    margin: 0 0 8px 0;
    font-weight: 500;
  }
`;

const AllocationGroupGray = styled(AllocationGroup)`
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  color: ${(props) => props.theme.theme.text.primary};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.border.tertiary};
  }
`;

const AllocationGroupRowTwoCol = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 1fr) 60px;
  grid-gap: 18px;
`;

const AllocationGroupRed = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentRed};
  border: 1px solid ${(props) => props.theme.theme.colors.red};
  color: ${(props) => props.theme.theme.colors.red};
  /* margin: 8px 8px 0 8px; */

  .result-value {
    font-weight: 500;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.red};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.red};
  }
`;

const AllocationGroupGreen = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  color: ${(props) => props.theme.theme.colors.green};
  /* margin: 8px 8px 0 8px; */

  .result-value {
    font-weight: 500;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.green};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.green};
  }
`;

const SliderBlack = styled(Slider)`
  &.MuiSlider-root {
    color: ${(props) => props.theme.theme.colors.black};
  }
`;

const InputGroup = styled.div`
  box-sizing: border-box;
  display: grid;
  align-content: start;
  /* display: flex; */
  flex-wrap: wrap;
  /* padding: 8px 8px 0 8px; */
  /* border-radius: 8px; */
  /* padding: 12px 12px 0px 12px; */
  /* border: 1px solid ${(props) => props.theme.theme.border.tertiary}; */
  grid-template-columns: 1fr;
  /* grid-gap: 24px; */
  margin: 0 0 0px 0;

  /* h3 {
    color: ${(props) => props.theme.theme.text.primary};
    text-transform: uppercase;
    margin: 0 0 12px 0;
    font-weight: 500;
  } */

  @media (max-width: 500px) {
    /* padding: 24px 0px 0 0px; */
  }
`;

const CustomReactTooltip = styled(ReactTooltip)`
  max-width: 50vw;
`;

const SliderContainer = styled.div`
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  border-radius: 8px;
  padding: 8px;
`

const ResultsPanel = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  padding: 12px;
  display: flex;
  flex-wrap: wrap;

  .label {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 8px 0 0;
  }

  .result-value {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 4px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 12px 0 0;
  }

  h3 {
    color: ${(props) => props.theme.theme.colors.green};
  }

  @media (max-width: 500px) {
    margin: 0px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  padding: 4px 0;

  @media (max-width: 500px) {
  }
`;

const FinancesTableWrapper = styled.div`
  overflow-x: scroll;
  border: 1px solid ${(props) => props.theme.theme.border.secondary};

  @media (max-width: 500px) {
    max-width: 90vw;
  }
`;
const FinancesTableGrid = styled.div`
  display: grid;
  margin: 0 auto;
`;

const FinancesTableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 150px);
  justify-items: center;
  padding: 4px;
  grid-gap: 16px;
  border-top: 1px solid ${(props) => props.theme.theme.border.secondary};

  &.labels-row {
    font-weight: 600;
    border: none;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(5, 85px);
  }
`;

const ColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) ) ;
  grid-gap: 24px;
`

const ContainerContent = styled.div`
  max-width: ${props => props.theme.theme.contentWidths.content};
`

const ContainerTwoCol = styled(ContainerMain)`
  display: grid;
  grid-template-columns: ${props => props.theme.theme.contentWidths.content} 1fr;
  grid-gap: 48px;
  padding-right: 0px;
  align-items: start;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    padding-right: 24px;
  }
`

const Image = styled.img`
  width: 100%;
`

const Divider = styled.div`
  height: 1px;  
  background-color: ${ props => props.theme.theme.border.secondary};
  margin: 0 0 12px 0;
`

const CustomTooltip = (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    console.log(payload)
    console.log(label)

    return(
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Month #: </strong>
          {payload[0].payload.month}
        </CardText400>

        <CardText400>
          <strong>Monthly payment: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.monthlyPayment.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Payment towards interest: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.interestPortion.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Payment towards principal: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.principalPortion.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>

        <CardText400>
          <strong>Loan Balance: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.loanBalance.toFixed(2)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    )
  }
  return(null)

}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

function NumberFormatCustomTwoDecimal(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

function NumberFormatGasPrice(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
      suffix=" per gallon"
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

function NumberFormatElectricityRate(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
      suffix=" per kWh"
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

function NumberFormatBasic(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

function NumberFormatBatteryCapacity(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      suffix=" kWh"
      thousandSeparator
      isNumericString
    />
  );
}

function NumberFormatRange(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      suffix=" miles"
      thousandSeparator
      isNumericString
    />
  );
}

function NumberFormatMPG(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      suffix=" miles per gallon"
      thousandSeparator
      isNumericString
    />
  );
}

function NumberFormatAnnualMileage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      suffix=" miles / year"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const HybridVsGasSavingsCalc = () => {

  const [ annualMileage, setAnnualMileage ] = useState(13500)
  const [ localFuelPrice, setLocalFuelPrice ] = useState(4.40)
  const [ vehicle1MPG, setVehicle1MPG ] = useState(25)
  const [ vehicle2MPG, setVehicle2MPG ] = useState(50)
  const [ vehicle1PurchasePrice, setVehicle1PurchasePrice ] = useState(25000)
  const [ vehicle2PurchasePrice, setVehicle2PurchasePrice ] = useState(30000)
  const [ invoiceTotal, setInvoiceTotal ] = useState()
  const [ lifetimeEarnings, setLifetimeEarnings ] = useState()

  const annualFuelCostVehicle1 = (annualMileage / vehicle1MPG) * localFuelPrice
  const annualFuelCostVehicle2 = (annualMileage / vehicle2MPG) * localFuelPrice
  const annualSavings = (annualFuelCostVehicle2 - annualFuelCostVehicle1)
  const breakEvenPoint = ( vehicle1PurchasePrice - vehicle2PurchasePrice ) / annualSavings

  const totalEarnings = parseFloat(invoiceTotal) + parseFloat(lifetimeEarnings)
  let invoiceTakeHome = 0
  let lifetimeTakeHome = 0

  console.log(totalEarnings)

  if( totalEarnings < 500 ){
    console.log("loop 1")
    invoiceTakeHome = invoiceTotal * 0.8
    lifetimeTakeHome = totalEarnings * 0.8
  }

  if( totalEarnings >= 500 && totalEarnings <= 10000 ){
    console.log("loop 2")
    lifetimeTakeHome = (500*0.8) + (totalEarnings - 500) * 0.9
    if(lifetimeEarnings < 500){
      invoiceTakeHome = (500-lifetimeEarnings) * 0.8 + (totalEarnings - 500) * 0.9
    } else {
      invoiceTakeHome = invoiceTotal * 0.9
    }
  }

  if( totalEarnings > 10000 ){
    console.log("loop 3")
    lifetimeTakeHome = (500*0.8) + (950 * 0.9) + (totalEarnings - 10000) * 0.95

    if(lifetimeEarnings < 500){
      invoiceTakeHome = ( (500-lifetimeEarnings) * 0.8 ) + (9500 * 0.9) + (totalEarnings - 10000) * 0.95
    } 
    if( lifetimeEarnings >= 500 && lifetimeEarnings <= 10000 ) {
      invoiceTakeHome = ( (10000 - lifetimeEarnings) * 0.9 ) + (totalEarnings - 10000) * 0.95
    }
    else {
      invoiceTakeHome = invoiceTotal * 0.95
    }
  }

  const upworkFee = invoiceTotal - invoiceTakeHome
  const upworkFeePercent = invoiceTotal ? 100 * (upworkFee / invoiceTotal) : ""

  const lifetimeUpworkFee = totalEarnings - lifetimeTakeHome
  const lifetimeUpworkFeePercent = totalEarnings ? 100 * (lifetimeUpworkFee / totalEarnings) : ""


  return (
    <Layout>
      <SEO
        title={'Upwork Fee Calculator and Guide'}
        description={'Calculate your take home earnings as a freelancer on Upwork'}
      />
      <CustomReactTooltip />

      <CalculatorPanel>
        <CalculatorGrid>
          <form>

            <FlexRow>
              <FlexInput style={{ width: "350px" }}>
                <InputItem>
                  <ThemedTextarea
                  id="outlined-required"
                  label="Your invoice total"
                  value={invoiceTotal}
                  onChange={event => setInvoiceTotal(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the value of a specific invoice with an Upwork client.`}
                />
                </InputItem>
              </FlexInput>

              <FlexInput style={{ width: "350px" }}>
                <InputItem>
                  <ThemedTextarea
                  id="outlined-required"
                  label="Your prior lifetime earnings with this client"
                  value={lifetimeEarnings}
                  onChange={event => setLifetimeEarnings(event.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the total amount of all work you have previously invoiced with this particular client through Upwork. If this is your first job with this client, leave it at $0.`}
                />
                </InputItem>
              </FlexInput>
            
            </FlexRow>

            <Divider />

            <FlexRow>
              <FlexInput style={{ width: "350px" }}>
                <AllocationGroupGray>
                  <Label>
                    Your take-home from this invoice
                    <MdHelp
                      size="1.2rem"
                      data-tip={`This is the amount of earnings you take home from this invoice after Upwork's fee.`}
                    />
                  </Label>
                  { invoiceTakeHome ? 
                    <span className="result-value">
                      <NumberFormat
                        displayType={"text"}
                        value={invoiceTakeHome.toFixed(0)}
                        thousandSeparator
                        prefix=" $"
                      /> 
                    </span> : ""
                }
                </AllocationGroupGray>

              </FlexInput>

              <FlexInput style={{ width: "350px" }}>
                <AllocationGroupGray>
                  <Label>
                    Upwork's fee on this invoice
                    <MdHelp
                      size="1.2rem"
                      data-tip={`This is the fee that Upwork will charge on this invoice based on your previous earnings with this client on Upwork.`}
                    />
                  </Label>
                  { upworkFee ?
                  <span className="result-value">
                    <NumberFormat
                      displayType={"text"}
                      value={upworkFee.toFixed(0)}
                      thousandSeparator
                      prefix="$"
                      suffix="  "
                    />
                  </span> : ""
                  }
                  {upworkFeePercent ?       
                  
                  <span className="result-value">
                    (<NumberFormat
                      displayType={"text"}
                      value={upworkFeePercent.toFixed(1)}
                      suffix=" %"
                    />)
                  </span>
                    : ""
                  }

                </AllocationGroupGray>
              </FlexInput>

              
            </FlexRow>

            <FlexRow>
            <FlexInput style={{ width: "350px" }}>
                <AllocationGroupGray>
                  <Label>
                    Your total lifetime invoices from this client
                    <MdHelp
                      size="1.2rem"
                      data-tip={`This is the total value of all earnings from this client, before Upwork's Fee.`}
                    />
                  </Label>
                  {totalEarnings ? 
                    <span className="result-value">
                      <NumberFormat
                        displayType={"text"}
                        value={totalEarnings.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                        suffix=""
                      />
                    </span>: ""
                  }

                </AllocationGroupGray>
              </FlexInput>

              <FlexInput style={{ width: "350px" }}>
                <AllocationGroupGray>
                  <Label>
                    Your total lifetime take-home from this client
                    <MdHelp
                      size="1.2rem"
                      data-tip={`This is the total amount of all lifetime earnings from this client after Upwork's fee.`}
                    />
                  </Label>
                  {lifetimeTakeHome ?
                    <span className="result-value">
                      <NumberFormat
                        displayType={"text"}
                        value={lifetimeTakeHome.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                        suffix="  "
                      />
                    </span> : ""
                  
                  }
                </AllocationGroupGray>
              </FlexInput>

              <FlexInput style={{ width: "350px" }}>
                <AllocationGroupGray>
                  <Label>
                    Upwork's total lifetime fees from this client
                    <MdHelp
                      size="1.2rem"
                      data-tip={`This is the total value of all Upwork fees from your lifetime work with this client.`}
                    />
                  </Label>
                  { lifetimeUpworkFee ?
                  <span className="result-value">
                    <NumberFormat
                      displayType={"text"}
                      value={lifetimeUpworkFee.toFixed(0)}
                      thousandSeparator
                      prefix="$"
                      suffix="  "
                    />
                  </span> : ""

                  }
                  {lifetimeUpworkFeePercent ?       
                  
                  <span className="result-value">
                    (<NumberFormat
                      displayType={"text"}
                      value={lifetimeUpworkFeePercent.toFixed(1)}
                      suffix=" %"
                    />)
                  </span>
                    : ""
                  }

                </AllocationGroupGray>
              </FlexInput>

            </FlexRow>

          </form>

        </CalculatorGrid>

      </CalculatorPanel>

    </Layout>
  )
}

export default HybridVsGasSavingsCalc
